// eslint-disable-next-line no-redeclare,no-unused-vars
function addTitle(view, cont) {
  var contIcon;
  var contWarning;
  var contAddition;
  var contTitle = $('<span></span>').appendTo(cont);

  view.setTitle = function (title, icon, warning, addition) {
    contTitle.html(title);
    if (icon) {
      if (contIcon) contIcon.remove();
      contIcon = $(
        '<span><i class="icon fa-regular fa-' +
          icon +
          ' fa-fw me-2"></i></span>'
      ).prependTo(contTitle.parent());
    }
    if (warning) {
      if (contWarning) contWarning.remove();
      contWarning = $(
        '<span class="badge rounded-pill me-2 bg-' + warning + '"></span>'
      ).insertBefore(contTitle);
    }
    if (addition) {
      if (contAddition) contAddition.html(' (' + addition + ')');
      else
        contAddition = $('<span> (' + addition + ')</span>').insertAfter(
          contTitle
        );
    } else if (contAddition) contAddition.html('');

    view.title = title;
  };
}
